import {createAction, props} from '@ngrx/store';
import {packageGroupDto} from "../models/packageGroup.entities";


export const loadPackagesGroups = createAction('[User] Load PackagesGroups',props<{filter:any}>());
export const loadPackagesGroupsSuccess = createAction('[User] Load PackagesGroups Success', props<{ packageGroups: packageGroupDto[] }>());
export const loadPackagesGroupsFailure = createAction('[User] Load PackagesGroups Failure', props<{ error: any }>());

export const addPackageGroup = createAction('[PackageGroup] Add PackageGroup', props<{ packageGroup: packageGroupDto }>());
export const addPackageGroupSuccess = createAction('[PackageGroup] Add PackageGroup Success', props<{ packageGroup: packageGroupDto }>());
export const addPackageGroupFailure = createAction('[PackageGroup] Add PackageGroup Failure', props<{ error: any }>());

export const updatePackageGroup = createAction('[PackageGroup] Update PackageGroup', props<{ id: number, packageGroup: packageGroupDto }>());
export const updatePackageGroupSuccess = createAction('[PackageGroup] Update PackageGroup Success', props<{ packageGroup: packageGroupDto }>());
export const updatePackageGroupFailure = createAction('[PackageGroup] Update PackageGroup Failure', props<{ error: any }>());

export const deletePackageGroup = createAction('[PackageGroup] Delete PackageGroup', props<{ id: number }>());
export const deletePackageGroupSuccess = createAction('[PackageGroup] Delete PackageGroup Success', props<{ id: number }>());
export const deletePackageGroupFailure = createAction('[PackageGroup] Delete PackageGroup Failure', props<{ error: any }>());
